// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("bootstrap")

$(document).on('ready turbolinks:load', function() {
	var MenuCtrl = {
		classes:{
			toggleButton:'toggle-mobile-menu-btn',
			toggleClass:'mobile-menu--opened',
		},
		elements:{
			toggleButton:null,
		},
		registerEvents:function(){
			if(this.elements.toggleButton === null)return false;
			var _self = this;
			$('body').on('click','.' + this.classes.toggleButton, function(e){
				$('body').toggleClass(_self.classes.toggleClass);
			});
		},
		init:function(){
			var $toggleButton = $('.' + this.classes.toggleButton);
			if($toggleButton.length){
				this.elements.toggleButton = $toggleButton;
				this.registerEvents();
			}
		}
	}.init();
});

// stylesheets
require("../stylesheets/main.scss")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
